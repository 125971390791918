<template>
    <div>
        <ly-crud ref="lycrud"  v-bind="crudConfig" >
            <template v-slot:customHandle="slotProps">
                <!-- <span class="table-operate-btn" @click="handleClick(slotProps.row,'disable')" v-show="hasPermission(this.$route.name,'Disable')">
                    <span v-if="slotProps.row.is_active">禁用</span>
                    <span v-else>启用</span>
                </span> -->
            </template>
        </ly-crud>
    </div>
</template>

<script lang="jsx">
    import {RechargeOrder,RechargeOrderAdd,RechargeOrderDelete,RechargeOrderEdit} from '@/api/api'
    import LyCrud from "@/components/lycrud";
    import templateData from "@/components/dict/crudTemplateData"
    import { h,resolveComponent } from 'vue';
    export default {
        // name: "userManageCrud",
        components: {LyCrud},
        data(){
            return{
                //crud配置
                crudConfig:{
                    //crud请求方法配置
                    crudRequest:{
                        add:RechargeOrderAdd,
                        del:RechargeOrderDelete,
                        edit:RechargeOrderEdit,
                        search:RechargeOrder,
                    },
                    //搜索栏目配置
                    searchBar:{
                        showSearchBar:true,//显示搜索栏目
                        searchColums:[
                            {label:'用户名',type:'input',prop:'user_name',width:200,maxlength:60,placeholder:'请输入用户名'},
                            {label:'手机号',type:'input',prop:'user_phone',width:200,maxlength:60,placeholder:'请输入手机号'},
                            {label:'支付状态',type:'select',prop:'pay_status',width:100,placeholder:'请选择',
                                options:[
                                    {value:1,label:'已支付'},
                                    {value:0,label:'未支付'}
                                ]
                            },
                            {label:'创建时间',type:'datepicker-datetimerange',prop:'timers'},
                        ]
                    },
                    //显示分页
                    showPagination:true,
                    //分页配置
                    pageparams: {
                        limit: 10,//每页显示的条数(默认每页显示10条)//非必填
                        // pageSizes:[10,20,30,40,50,200],//非必填
                        // layout:'total, sizes, prev, pager, next, jumper',//非必填
                    },
                    //crud按钮配置
                    rowHandle:{
                        width: 180,//操作列宽度,0表示不显示表格操作列
                        fixed:"right",//固定操作列在右侧
                        permission:{//增删改查按钮权限控制（是否显示）
                            add:this.hasPermission(this.$route.name,'Create'),//bool型 this.$options.name获取当前组件的name本例为userManageCrud
                            del:this.hasPermission(this.$route.name,'Delete'),
                            edit:this.hasPermission(this.$route.name,'Update'),
                            search:this.hasPermission(this.$route.name,'Search'),
                            detail:this.hasPermission(this.$route.name,'Retrieve'),
                        }
                    },
                    //crud弹窗属性
                    formOptions: {
                        width:'45%',//dialog弹窗宽度：类型：百分比或字符串
                        gutter: 20, // Layout布局栅格间隔
                    },
                    //crud表格属性
                    tableOptions:{
                        border:true,
                        showHeader:true,
                    },
                    showSelectable:true,//表格显示复选项框
                    //table表头列
                    tableColumns:[
                        {label:'ID',prop:'id',type:'input',minWidth:'100',sortable: false,hidden:true,
                            form:{
                                 //表单属性
                                 span:24,
                                 hidden:true,//编辑时隐藏，添加时去除
                             }
                        },
                        {label:'订单编号',type:'input',prop:'order_no',minWidth:'150',sortable: false,hidden:false,
                            form:{
                                //表单属性
                                span:24,
                                // width:80,头像大小默认80px
                            }

                         },
                         {label:'用户名称',prop:'user_name',type:'input',minWidth:'110',sortable: false,hidden:false,
                            form:{
                                //表单属性
                                span:12,
                            }
                         },
                         {label:'用户手机',prop:'user_phone',type:'input',minWidth:'110',sortable: false,hidden:false,
                            form:{
                                //表单属性
                                span:12,
                            }
                         },
                        {label:'充值金额',prop:'recharge_goods_name',type:'input',minWidth:'50',sortable: false,hidden:false,
                            form:{
                                //表单属性
                                span:12,
                            }
                         },
                         {label:'支付金额',prop:'price',type:'input',minWidth:'50',sortable: false,hidden:false,
                            form:{
                                //表单属性
                                span:12,
                            }
                         },
                         {label:'充值类型',prop:'recharge_type',type:'radio',minWidth:'100',sortable: false,hidden:false,
                                render: (row) => {
                                let elTag = resolveComponent('el-tag')
                                switch(row.recharge_type){
                                    case 1:
                                    return h(elTag, {
                                        type:'success'
                                    },()=>'自由充值');
                                    case 2:
                                    return h(elTag, {
                                        type:'warning'
                                    },()=>'固定充值');
                                }
                                },
                                form:{
                                //表单属性
                                span:24,
                                options:templateData.RECHARGE_TYPE,//radio选项属性值
                                }
                            },
                         {label:'支付状态',prop:'pay_status',type:'radio',minWidth:'50',sortable: false,hidden:false,
                            render: (row) => {
                                let elTag = resolveComponent('el-tag')
                                if(row.pay_status === 0){
                                    return h(elTag, {
                                    type:'warning'
                                    },()=>'未支付');
                                }else if(row.pay_status === 1) {
                                    return h(elTag, {
                                    type:'success'
                                    },()=>'已支付');
                                }
                                },
                             form:{
                                 //表单属性
                                 span:24,
                                 options:templateData.PAY_STATUS,//radio选项属性值
                                 // valueChange(e){//值变化回调事件
                                 //     console.log(e,'---变化的值')
                                 // },
                             }
                         },
                         {label:'创建时间',prop:'create_datetime',minWidth:'150',sortable: false,hidden:false},
                    ],
                },

            }
        },
        methods:{
            setFull(){
                this.$refs.lycrud.setFull()
            },
            //自定义操作列按钮方法
            handleClick(row,flag){
                let vm = this
                if(flag=='disable'){
                    UsersUsersdisableEdit({id:row.id}).then(res=>{
                        if(res.code == 2000) {
                            vm.$message.success(res.msg)
                            vm.$refs.lycrud.handleRefresh()//刷新表格数据
                        } else {
                            vm.$message.warning(res.msg)
                        }
                    })
                }
            },
        },
    }
</script>

<style scoped>

</style>